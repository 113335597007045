import michaelImg from "../images/michael.jpg";
import { Container } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <>
            <Container className="mt-lg-4 mt-2 d-flex justify-content-center align-itmes-center">
                <section className="home row mt-3 w-100">
                    <div className="col-lg-6 col-12 text-center d-flex align-items-center justify-content-center p-3">
                        <img src={michaelImg} alt="" className="img-fluid rounded-circle home-img border border-warning p-2" />
                    </div>
                    <div className="col-lg-6 col-12 d-flex flex-column align-items-lg-start align-items-center justify-content-center p-3">
                        <p className="fs-5 m-0 p-0 text-center lead ">Hello, I'm</p>
                        <p className="home-text m-0 p-0 text-center">Michael Ikoko</p>
                        <p className="fs-4 lead">A fullstack web developer</p>
                        {/*<Button variant="danger" className="text-white"><FontAwesomeIcon icon={faDownload} color="#fff" /> Resume</Button>*/}
                    </div>
                </section>
            </Container>
            <div>
                <Footer />
            </div>
        </>
    );
};