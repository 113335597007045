import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import ContactMe from "./pages/ContactMe";
import Projects from "./pages/Projects";
import SharedLayout from "./components/SharedLayout";

export default function App(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<SharedLayout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="contact" element={<ContactMe />} /> 
                </Route>
            </Routes>
        </BrowserRouter>
    );
};