import { Container } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5, faCss3, faJs, faBootstrap, faReact, faPython, faNodeJs } from "@fortawesome/free-brands-svg-icons";
import { Django, DRF, Postgres, Webpack } from "../components/icons";
import FCC_PYTHON_CERT from "../images/FCC_PYTHON_CERT.png";
import FCC_WEB_CERT from "../images/FCC_WEB_CERT.jpg";
import HR_PYTHON_CERT from "../images/HR_PYTHON_CERT.png";
import NH_PYTHON_CERT from "../images/NH_PYTHON_CERT.jpg";
import Footer from "../components/Footer";

export default function About() {
    return (
        <>
            <Container className="mt-lg-5 mt-3">
                <div className="mb-5">
                    <div className="d-flex flex-column align-items-start justify-content-center">
                        <span className="h3">About Me</span>
                        <span className="underline"></span>
                    </div>
                    <p className="mt-4 lead">
                        Hello, I am Michael Ikoko. I am from Delta state, Nigeria. I am a fullstack web developer.
                        I am passionate about the use and applications of web technologies to improve the lives of people.
                    </p>
                    <p className="lead">
                        My goal is to build accessible, scalable and efficient web applications.
                        I am currently an undergraduate of Electrical and Electronics Engineering at the Federal University of Technology, Akure.
                        I am open minded, eager to learn and grow, and resolute.
                    </p>
                </div>
                <div className="mb-5">
                    <div className="d-flex flex-column align-items-start justify-content-center"><span className="h3">Skills</span><span className="underline"></span></div>
                    <ListGroup className="mt-4">
                        <ListGroup.Item><FontAwesomeIcon icon={faHtml5} className="text-warning" size="xl" /> <span className="ms-3">HTML</span>      </ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faCss3} className="text-warning" size="xl" /> <span className="ms-3">CSS</span>       </ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faJs} className="text-warning" size="xl" /> <span className="ms-3">JAVASCRIPT</span></ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faNodeJs} className="text-warning" size="xl" /> <span className="ms-3">NODE JS</span>   </ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faBootstrap} className="text-warning" size="xl" /> <span className="ms-3">BOOTSTRAP</span> </ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faReact} className="text-warning" size="xl" /> <span className="ms-3">REACT</span>     </ListGroup.Item>
                        <ListGroup.Item><span><Webpack /></span> <span className="ms-3">WEBPACK</span></ListGroup.Item>
                        <ListGroup.Item><FontAwesomeIcon icon={faPython} className="text-warning" size="xl" /> <span className="ms-3">PYTHON</span>    </ListGroup.Item>
                        <ListGroup.Item><span><Django /></span> <span className="ms-3">DJANGO</span></ListGroup.Item>
                        <ListGroup.Item><span><DRF /></span> <span className="ms-3">DJANGO REST FRAMEWORK</span></ListGroup.Item>
                        <ListGroup.Item><span><Postgres /></span> <span className="ms-3">POSTGRES SQL</span></ListGroup.Item>
                    </ListGroup>
                </div>
                <div className="mb-5">
                    <div className="d-flex flex-column align-items-start justify-content-center"><span className="h3">Certifications</span><span className="underline"></span></div>
                    <ListGroup className="mt-4">
                        <ListGroup.Item className="w-75  mt-3"><img src={NH_PYTHON_CERT} alt="new horizons python certificate" className="img-fluid" /></ListGroup.Item>
                        <ListGroup.Item className="w-75  mt-3">
                            <a href="https://www.freecodecamp.org/certification/michaelikoko/scientific-computing-with-python-v7" target="_blank" rel="noopener noreferrer">
                                <img src={FCC_PYTHON_CERT} alt="hacker rank python certificate" className="img-fluid" />
                            </a>
                        </ListGroup.Item>
                        <ListGroup.Item className="w-75  mt-3">
                            <a href="https://www.hackerrank.com/certificates/7e2955139f1a" target="_blank" rel="noopener noreferrer">
                                <img src={HR_PYTHON_CERT} alt="freecodecamp python certificate" className="img-fluid" />
                            </a>
                        </ListGroup.Item>
                        <ListGroup.Item className="w-75  mt-3">
                            <a href="https://www.freecodecamp.org/certification/michaelikoko/responsive-web-design" target="_blank" rel="noopener noreferrer">
                                <img src={FCC_WEB_CERT} alt="freecodecamp web development certificate" className="img-fluid" />
                            </a>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </Container>
            <div>
                <Footer />
            </div>
        </>
    );
};