import { useState } from "react";
import { Container, Form, FloatingLabel, Button, Spinner, Alert } from "react-bootstrap";
import Footer from "../components/Footer";
import emailjs from '@emailjs/browser';

export default function ContactMe() {
    const [formData, setFormData] = useState({
        name: "",
        email_address: "",
        message: ""
    })

    const [sendMessageLoading, setSendMessageLoading] = useState(false)
    const [sendMessageFailed, setSendMessageFailed] = useState(false)
    const [sendMessageSuccess, setSendMessageSuccess] = useState(false)

    function handleChange(event) {
        const { name, value } = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        })
    }

    async function handleSubmit(event) {
        event.preventDefault()
        try {
            setSendMessageLoading(true)
            const response = await emailjs.send(
                process.env.REACT_APP_SERVICE_ID, 
                process.env.REACT_APP_TEMPLATE_ID, 
                {
                    name: formData.name,
                    email_address: formData.email_address,
                    message: formData.message
                },
                process.env.REACT_APP_PUBLIC_KEY
            )
            console.log(response)
            setSendMessageLoading(false)
            setSendMessageSuccess(true)
            setFormData({
                name: "",
                email_address: "",
                message: ""
            })
        }
        catch (error) {
            console.log(error)
            setSendMessageLoading(false)
            setSendMessageFailed(true)
        }

    }

    return (
        <>
            <Container className="mt-lg-5 mt-3" style={{minHeight: "85vh"}}>
                <Alert variant="danger" show={sendMessageFailed} onClose={() => setSendMessageFailed(false)} className="fixed-top" dismissible>
                    An error occurred.
                </Alert>
                <Alert variant="success" show={sendMessageSuccess} onClose={() => setSendMessageSuccess(false)} className="fixed-top" dismissible>
                    Message sent successfully.
                </Alert>
                <div className="mt-4">
                    <div className="d-flex flex-column align-items-start justify-content-center"><span className="h3">CONTACT ME</span><span className="underline"></span></div>
                </div>
                <div className="mb-5 mt-4">
                    <Form onSubmit={handleSubmit}>
                        <Container>
                            <FloatingLabel
                                controlId="floatingInput1"
                                label="Name:"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="name" name="name" value={formData.name} onChange={handleChange} required />
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="floatingInput3"
                                label="Email address:"
                                className="mb-3"
                            >
                                <Form.Control type="email" placeholder="name@example.com" name="email_address" value={formData.email_address} onChange={handleChange} required />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingTextarea2" label="Message:">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '10rem' }}
                                    className="mb-3"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </FloatingLabel>
                            <Button variant="primary" className="p-2" type="submit">
                                {
                                    sendMessageLoading &&
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                                <span className="ms-1">Send Message</span>
                            </Button>
                        </Container>
                    </Form>
                </div>
            </Container>
            <div>
                <Footer />
            </div>
        </>
    );
};