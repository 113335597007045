import { Navbar, Nav, Container, } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Logo } from "./icons";

export default function NavBar() {

    return (
        <Navbar  variant="light" collapseOnSelect expand="md" bg="white" className="shadow p-3">
            <Container>
                <Navbar.Brand className="fw-bold">
                    <NavLink to="/" className="text-decoration-none ">
                        <Logo />
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
                <Navbar.Collapse
                    id="offcanvasNavbar-expand-md"
                    aria-labelledby="offcanvasNavbarLabel-expand-md"
                    placement="end"
                >
                    <Nav className="justify-content-end w-100 align-items-start ">
                        <NavLink
                            className={({ isActive }) => (isActive ? "nav-link-active ms-3 p-1 text-dark" : "nav-link ms-3 p-1 text-dark")}
                            to="/"
                            end
                        >
                            Home
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => (isActive ? "nav-link-active ms-3 p-1 text-dark" : "nav-link ms-3 p-1 text-dark")}
                            to="about"
                        >
                            About
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => (isActive ? "nav-link-active ms-3 p-1 text-dark" : "nav-link ms-3 p-1 text-dark")}
                            to="projects"
                        >
                            Projects
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => (isActive ? "nav-link-active ms-3 p-1 text-dark" : "nav-link ms-3 p-1 text-dark")}
                            to="contact"
                        >
                            Contact Me
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};