import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";

export default function SharedLayout(){
    return (
        <>
            <div className="position-sticky">
                <NavBar />
            </div>
            <Outlet />
        </>
    );
};