import { Container, Button, ListGroup } from "react-bootstrap";
import poller from "../images/poller.png";
import amp from "../images/amp.png";
import todo from "../images/todo.png";
import weather from "../images/weather.png";
import memory_game from "../images/memory_game.png";
import calculator from "../images/calculator.png";
import quizzical from "../images/quizzical.png";
import tenzies from "../images/tenzies.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";

export default function Projects() {
    return (
        <>
            <Container className="mt-lg-5 mt-3">
                <div className="mt-4">
                    <div className="d-flex flex-column align-items-start justify-content-center"><span className="h3">Projects</span><span className="underline"></span></div>
                </div>
                <div className="mb-5">
                    <ListGroup variant="flush">
                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={poller} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">POLLER</p>
                                    <p className="lead">
                                        Poller provides you with an easier way to create elections online. Voting can be done on any device and in any location.
                                        The site was made using Django, DRF, react and webpack.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Poller" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://poller.pythonanywhere.com/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={amp} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">AMP ELECTRONICS</p>
                                    <p className="lead">
                                        Amp electronices is an ecommerce store. The back-end (API and admin page) was made using Django and DRF, and the front-end was
                                        made using React. JWT is used for authentication.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Ecommerce-Store-Frontend" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>

                                        <a href="https://amp-electronics.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={todo} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">TODO APP</p>
                                    <p className="lead">
                                        A todo app with user authentication and registration. The app was made using Django.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Todo_app" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://mitodolist.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={weather} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">WEATHER INFO</p>
                                    <p className="lead">
                                        Weather Info is a web app that shows the weather forcast. It was made using REACT, and OpenWeatherAPI.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Weather-Info" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://weather-info-web.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={memory_game} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">MEMORY GAME</p>
                                    <p className="lead">
                                        A game where the player matches each pair of unique tiles in the least amount of time. It was made using React.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Memory-Game" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://memory-game-react-web.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={calculator} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">CALCULATOR</p>
                                    <p className="lead">
                                        A basic calculator made using React.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Calculator" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://calculator-react-web.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={quizzical} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">QUIZZICAL</p>
                                    <p className="lead">
                                        Quizzical is a quiz game that tests the player's general knowledge.
                                        It was made using React and the Open trivial Database.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Quizzical" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://quizzical-react-web.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>

                        <ListGroup.Item className="bg-transparent pt-5 pb-5">
                            <div className="row g-3">
                                <div className="col-lg-4 col-12">
                                    <img src={tenzies} className="img-fluid img-thumbnail" alt="" />
                                </div>
                                <div className="col-lg-8 col-12">
                                    <p className="h4 fw-bold">TENZIES</p>
                                    <p className="lead">
                                        Tenzies is a game where the player rolls 10 dice until all dice are on the same number.
                                        It was made using React.
                                    </p>
                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                        <a href="https://github.com/michaelikoko/Tenzies" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="info" className="text-white d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faGithub} size="lg" />
                                                <span className="ms-2 text-center">View on Github</span>
                                            </Button>
                                        </a>
                                        <a href="https://tenzies-react-web.vercel.app/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                            <Button variant="warning" className="text-white d-flex align-items-center justify-content-center ms-2">
                                                <FontAwesomeIcon icon={faLaptop} size="lg" />
                                                <span className="ms-2 text-center">Visit live site</span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </div>
            </Container>
            <div>
                <Footer />
            </div>
        </>

    );
};  